import type { Ref } from 'vue'

import isObject from 'lodash/isObject'
import transform from 'lodash/transform'

import { useRoute, navigateTo, useAPI } from '#imports'

import type r4 from 'fhir/r4'

import { useExternalStorage } from '@/composables/useExternalStorage'
import { options } from '@/generated/mfxAutoRenderer'
import type { Source } from '@/stores/sources'

/**
 * Returns the resolved library version based on the provided value. If the value is not found, it will default to the lowest supported version.
 */
export const resolveLibraryVersion = (value?: string) => {
  return (
    options.find((option) => {
      return String(value) === String(option.value)
    }) ?? options[options.length - 1]
  ).value
}

/**
 * Returns the route name for the preview route based on the provided library version.
 */
export const getLibraryVersionPreviewRouteName = (libraryVersion: string) => {
  return `preview-${libraryVersion.replaceAll('/', '-')}`
}

/**
 * Returns the async data function for any preview page.
 */
export const getPreviewAsyncData =
  (libraryVersion: string, source: Ref<Source | undefined>) => async () => {
    const route = useRoute()
    const api = useAPI()
    const externalStorage = useExternalStorage()

    // External source preview
    if (route.query.external) {
      const response = await externalStorage.get(String(route.query.external))

      const expectedVersion = resolveLibraryVersion(response.version)

      if (expectedVersion !== libraryVersion) {
        await navigateTo({
          name: getLibraryVersionPreviewRouteName(expectedVersion),
          query: route.query,
        })
      }

      return {
        config: response.config,
        resources: [...response.sources, ...response.responses],
      }
    }

    // Application preview
    if (route.query.application) {
      const response = await api.Applications.getWithRelated(
        String(route.query.application),
      )

      const expectedVersion = resolveLibraryVersion(
        response.dependencies['@visiontree/mfx-auto-renderer']?.version,
      )

      if (expectedVersion !== libraryVersion) {
        await navigateTo({
          name: getLibraryVersionPreviewRouteName(expectedVersion),
          query: route.query,
        })
      }

      const resourceVersionIDs = response.resourceDocuments
        .map((doc) => {
          return doc.currentVersionID
        })
        .filter((value: unknown): value is string => {
          return !!value
        })

      const resourceVersionsResponse = await api.ResourceVersions.all({
        keys: resourceVersionIDs,
      })

      const questionnaires = resourceVersionsResponse.rows
        .map((value) => {
          return 'doc' in value && !!value.doc && 'resource' in value.doc
            ? value?.doc?.resource
            : undefined
        })
        .filter((value): value is r4.Questionnaire => {
          return !!value
        })

      const questionnaireResponses = response.resourceDocuments
        .map((doc) => {
          return doc.legacyResponse
        })
        .filter((value): value is r4.QuestionnaireResponse => {
          return !!value
        })

      return {
        config: response.dependencies['@visiontree/mfx-auto-renderer'].config,
        resources: [...questionnaires, ...questionnaireResponses],
        documentIDs: [...response.resources, ...resourceVersionIDs],
      }
    }

    // Single resource preview
    if (source.value) {
      const expectedVersion = resolveLibraryVersion(source.value.version)

      if (expectedVersion !== libraryVersion) {
        await navigateTo({
          name: getLibraryVersionPreviewRouteName(expectedVersion),
          query: route.query,
        })
      }

      return {
        config: source.value.config,
        resources: source.value.jsonObject
          ? ([
              source.value.jsonObject,
              source.value.response,
            ] as r4.Questionnaire[])
          : [],
      }
    }
  }

const removeDeepByKey = <T extends object>(
  obj: T,
  keyToBeRemoved: string,
): T => {
  return transform(obj, (result, value, key) => {
    if (isObject(value)) {
      value = removeDeepByKey(value, keyToBeRemoved)
    }
    if (key !== keyToBeRemoved) {
      result[key] = value
    }
  })
}

type Resource = r4.Questionnaire | r4.QuestionnaireResponse

/**
 * Some modifications to the resources needs to happen to assist in debugging parts of the autorenderer.
 * Instead of modifying the library to support these features, we can strip them out here.
 */
export const cleanResourcesByRouteSettings = (
  route: ReturnType<typeof useRoute>,
  resources: Partial<Resource>[],
): Partial<Resource>[] => {
  return resources.map((resource) => {
    if (resource.resourceType === 'QuestionnaireResponse') {
      return resource
    }

    if (route.query.disableEnableWhen) {
      return removeDeepByKey(resource, 'enableWhen')
    }

    return resource
  })
}

import { default as danger_45zoneRjYeDVcKKCMeta } from "/vercel/path0/apps/mfx-studio/pages/applications/[slug]/danger-zone.vue?macro=true";
import { default as detailsHHEnvxYrwiMeta } from "/vercel/path0/apps/mfx-studio/pages/applications/[slug]/details.vue?macro=true";
import { default as resourcesAuI98jMFCtMeta } from "/vercel/path0/apps/mfx-studio/pages/applications/[slug]/resources.vue?macro=true";
import { default as _91slug_93vi6LrujBPhMeta } from "/vercel/path0/apps/mfx-studio/pages/applications/[slug].vue?macro=true";
import { default as indexHpJqphEj03Meta } from "/vercel/path0/apps/mfx-studio/pages/applications/index.vue?macro=true";
import { default as debugzbmLPe8XGrMeta } from "/vercel/path0/apps/mfx-studio/pages/debug.vue?macro=true";
import { default as codehsckVMqPp1Meta } from "/vercel/path0/apps/mfx-studio/pages/edit/questionnaires/[id]/code.vue?macro=true";
import { default as configurationrXA22jHlEhMeta } from "/vercel/path0/apps/mfx-studio/pages/edit/questionnaires/[id]/configuration.vue?macro=true";
import { default as index0pft1uCIsPMeta } from "/vercel/path0/apps/mfx-studio/pages/edit/questionnaires/index.vue?macro=true";
import { default as edito9eguJKymcMeta } from "/vercel/path0/apps/mfx-studio/pages/edit.vue?macro=true";
import { default as elementdefinition_45hiddenYvLP52Q6JDMeta } from "/vercel/path0/apps/mfx-studio/pages/fhir/StructureDefinition/elementdefinition-hidden.vue?macro=true";
import { default as elementdefinition_45orderMpe3WpkXlrMeta } from "/vercel/path0/apps/mfx-studio/pages/fhir/StructureDefinition/elementdefinition-order.vue?macro=true";
import { default as indexYpe4ZXompqMeta } from "/vercel/path0/apps/mfx-studio/pages/index.vue?macro=true";
import { default as _91id_93drDVroCem7Meta } from "/vercel/path0/apps/mfx-studio/pages/preview/[id].vue?macro=true";
import { default as mfx_45auto_45renderer_45_451_4511Seq1zSr9bhMeta } from "/vercel/path0/apps/mfx-studio/pages/preview/@visiontree/mfx-auto-renderer--1-11.vue?macro=true";
import { default as mfx_45auto_45renderer_45_451_4512AUqVEvr3LuMeta } from "/vercel/path0/apps/mfx-studio/pages/preview/@visiontree/mfx-auto-renderer--1-12.vue?macro=true";
import { default as mfx_45auto_45renderer_45_451_4513v7MYpXaJayMeta } from "/vercel/path0/apps/mfx-studio/pages/preview/@visiontree/mfx-auto-renderer--1-13.vue?macro=true";
import { default as mfx_45auto_45renderer_45_451_4515Aw0a2uVCPdMeta } from "/vercel/path0/apps/mfx-studio/pages/preview/@visiontree/mfx-auto-renderer--1-15.vue?macro=true";
import { default as mfx_45auto_45renderer_45_451_4516g8kqZovXSAMeta } from "/vercel/path0/apps/mfx-studio/pages/preview/@visiontree/mfx-auto-renderer--1-16.vue?macro=true";
import { default as mfx_45auto_45renderer_45_451_4517Wr3ljQAYF2Meta } from "/vercel/path0/apps/mfx-studio/pages/preview/@visiontree/mfx-auto-renderer--1-17.vue?macro=true";
import { default as mfx_45auto_45rendererobO9CLoNbgMeta } from "/vercel/path0/apps/mfx-studio/pages/preview/@visiontree/mfx-auto-renderer.vue?macro=true";
import { default as indexNqW3SY9OAqMeta } from "/vercel/path0/apps/mfx-studio/pages/preview/index.vue?macro=true";
import { default as index9nNtXabxV8Meta } from "/vercel/path0/apps/mfx-studio/pages/resources/[id]/index.vue?macro=true";
import { default as properties2C7wTsA1INMeta } from "/vercel/path0/apps/mfx-studio/pages/resources/[id]/properties.vue?macro=true";
import { default as Questionnaire_45itemxKCDA7fzZ6Meta } from "/vercel/path0/apps/mfx-studio/pages/resources/[id]/Questionnaire-item.vue?macro=true";
import { default as settingsuASeJHhbNnMeta } from "/vercel/path0/apps/mfx-studio/pages/resources/[id]/settings.vue?macro=true";
import { default as _91id_93yH9ipzaVXBMeta } from "/vercel/path0/apps/mfx-studio/pages/resources/[id].vue?macro=true";
import { default as iframevf6WKBfmxlMeta } from "/vercel/path0/apps/mfx-studio/pages/resources/import/iframe.vue?macro=true";
import { default as indexyutem8pLx0Meta } from "/vercel/path0/apps/mfx-studio/pages/resources/import/index.vue?macro=true";
import { default as indexTW6kGHxKT5Meta } from "/vercel/path0/apps/mfx-studio/pages/resources/index.vue?macro=true";
export default [
  {
    name: _91slug_93vi6LrujBPhMeta?.name ?? "applications-slug",
    path: _91slug_93vi6LrujBPhMeta?.path ?? "/applications/:slug()",
    children: [
  {
    name: danger_45zoneRjYeDVcKKCMeta?.name ?? "applications-slug-danger-zone",
    path: danger_45zoneRjYeDVcKKCMeta?.path ?? "danger-zone",
    meta: danger_45zoneRjYeDVcKKCMeta || {},
    alias: danger_45zoneRjYeDVcKKCMeta?.alias || [],
    redirect: danger_45zoneRjYeDVcKKCMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/applications/[slug]/danger-zone.vue").then(m => m.default || m)
  },
  {
    name: detailsHHEnvxYrwiMeta?.name ?? "applications-slug-details",
    path: detailsHHEnvxYrwiMeta?.path ?? "details",
    meta: detailsHHEnvxYrwiMeta || {},
    alias: detailsHHEnvxYrwiMeta?.alias || [],
    redirect: detailsHHEnvxYrwiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/applications/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: resourcesAuI98jMFCtMeta?.name ?? "applications-slug-resources",
    path: resourcesAuI98jMFCtMeta?.path ?? "resources",
    meta: resourcesAuI98jMFCtMeta || {},
    alias: resourcesAuI98jMFCtMeta?.alias || [],
    redirect: resourcesAuI98jMFCtMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/applications/[slug]/resources.vue").then(m => m.default || m)
  }
],
    meta: _91slug_93vi6LrujBPhMeta || {},
    alias: _91slug_93vi6LrujBPhMeta?.alias || [],
    redirect: _91slug_93vi6LrujBPhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/applications/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexHpJqphEj03Meta?.name ?? "applications",
    path: indexHpJqphEj03Meta?.path ?? "/applications",
    meta: indexHpJqphEj03Meta || {},
    alias: indexHpJqphEj03Meta?.alias || [],
    redirect: indexHpJqphEj03Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/applications/index.vue").then(m => m.default || m)
  },
  {
    name: debugzbmLPe8XGrMeta?.name ?? "debug",
    path: debugzbmLPe8XGrMeta?.path ?? "/debug",
    meta: debugzbmLPe8XGrMeta || {},
    alias: debugzbmLPe8XGrMeta?.alias || [],
    redirect: debugzbmLPe8XGrMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/debug.vue").then(m => m.default || m)
  },
  {
    name: edito9eguJKymcMeta?.name ?? "edit",
    path: edito9eguJKymcMeta?.path ?? "/edit",
    children: [
  {
    name: codehsckVMqPp1Meta?.name ?? "edit-questionnaires-id-code",
    path: codehsckVMqPp1Meta?.path ?? "questionnaires/:id()/code",
    meta: codehsckVMqPp1Meta || {},
    alias: codehsckVMqPp1Meta?.alias || [],
    redirect: codehsckVMqPp1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/edit/questionnaires/[id]/code.vue").then(m => m.default || m)
  },
  {
    name: configurationrXA22jHlEhMeta?.name ?? "edit-questionnaires-id-configuration",
    path: configurationrXA22jHlEhMeta?.path ?? "questionnaires/:id()/configuration",
    meta: configurationrXA22jHlEhMeta || {},
    alias: configurationrXA22jHlEhMeta?.alias || [],
    redirect: configurationrXA22jHlEhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/edit/questionnaires/[id]/configuration.vue").then(m => m.default || m)
  },
  {
    name: index0pft1uCIsPMeta?.name ?? "edit-questionnaires",
    path: index0pft1uCIsPMeta?.path ?? "questionnaires",
    meta: index0pft1uCIsPMeta || {},
    alias: index0pft1uCIsPMeta?.alias || [],
    redirect: index0pft1uCIsPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/edit/questionnaires/index.vue").then(m => m.default || m)
  }
],
    meta: edito9eguJKymcMeta || {},
    alias: edito9eguJKymcMeta?.alias || [],
    redirect: edito9eguJKymcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/edit.vue").then(m => m.default || m)
  },
  {
    name: elementdefinition_45hiddenYvLP52Q6JDMeta?.name ?? "fhir-StructureDefinition-elementdefinition-hidden",
    path: elementdefinition_45hiddenYvLP52Q6JDMeta?.path ?? "/fhir/StructureDefinition/elementdefinition-hidden",
    meta: elementdefinition_45hiddenYvLP52Q6JDMeta || {},
    alias: elementdefinition_45hiddenYvLP52Q6JDMeta?.alias || [],
    redirect: elementdefinition_45hiddenYvLP52Q6JDMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/fhir/StructureDefinition/elementdefinition-hidden.vue").then(m => m.default || m)
  },
  {
    name: elementdefinition_45orderMpe3WpkXlrMeta?.name ?? "fhir-StructureDefinition-elementdefinition-order",
    path: elementdefinition_45orderMpe3WpkXlrMeta?.path ?? "/fhir/StructureDefinition/elementdefinition-order",
    meta: elementdefinition_45orderMpe3WpkXlrMeta || {},
    alias: elementdefinition_45orderMpe3WpkXlrMeta?.alias || [],
    redirect: elementdefinition_45orderMpe3WpkXlrMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/fhir/StructureDefinition/elementdefinition-order.vue").then(m => m.default || m)
  },
  {
    name: indexYpe4ZXompqMeta?.name ?? "index",
    path: indexYpe4ZXompqMeta?.path ?? "/",
    meta: indexYpe4ZXompqMeta || {},
    alias: indexYpe4ZXompqMeta?.alias || [],
    redirect: indexYpe4ZXompqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93drDVroCem7Meta?.name ?? "preview-id",
    path: _91id_93drDVroCem7Meta?.path ?? "/preview/:id()",
    meta: _91id_93drDVroCem7Meta || {},
    alias: _91id_93drDVroCem7Meta?.alias || [],
    redirect: _91id_93drDVroCem7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/preview/[id].vue").then(m => m.default || m)
  },
  {
    name: mfx_45auto_45renderer_45_451_4511Seq1zSr9bhMeta?.name ?? "preview-@visiontree-mfx-auto-renderer--1-11",
    path: mfx_45auto_45renderer_45_451_4511Seq1zSr9bhMeta?.path ?? "/preview/@visiontree/mfx-auto-renderer--1-11",
    meta: mfx_45auto_45renderer_45_451_4511Seq1zSr9bhMeta || {},
    alias: mfx_45auto_45renderer_45_451_4511Seq1zSr9bhMeta?.alias || [],
    redirect: mfx_45auto_45renderer_45_451_4511Seq1zSr9bhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/preview/@visiontree/mfx-auto-renderer--1-11.vue").then(m => m.default || m)
  },
  {
    name: mfx_45auto_45renderer_45_451_4512AUqVEvr3LuMeta?.name ?? "preview-@visiontree-mfx-auto-renderer--1-12",
    path: mfx_45auto_45renderer_45_451_4512AUqVEvr3LuMeta?.path ?? "/preview/@visiontree/mfx-auto-renderer--1-12",
    meta: mfx_45auto_45renderer_45_451_4512AUqVEvr3LuMeta || {},
    alias: mfx_45auto_45renderer_45_451_4512AUqVEvr3LuMeta?.alias || [],
    redirect: mfx_45auto_45renderer_45_451_4512AUqVEvr3LuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/preview/@visiontree/mfx-auto-renderer--1-12.vue").then(m => m.default || m)
  },
  {
    name: mfx_45auto_45renderer_45_451_4513v7MYpXaJayMeta?.name ?? "preview-@visiontree-mfx-auto-renderer--1-13",
    path: mfx_45auto_45renderer_45_451_4513v7MYpXaJayMeta?.path ?? "/preview/@visiontree/mfx-auto-renderer--1-13",
    meta: mfx_45auto_45renderer_45_451_4513v7MYpXaJayMeta || {},
    alias: mfx_45auto_45renderer_45_451_4513v7MYpXaJayMeta?.alias || [],
    redirect: mfx_45auto_45renderer_45_451_4513v7MYpXaJayMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/preview/@visiontree/mfx-auto-renderer--1-13.vue").then(m => m.default || m)
  },
  {
    name: mfx_45auto_45renderer_45_451_4515Aw0a2uVCPdMeta?.name ?? "preview-@visiontree-mfx-auto-renderer--1-15",
    path: mfx_45auto_45renderer_45_451_4515Aw0a2uVCPdMeta?.path ?? "/preview/@visiontree/mfx-auto-renderer--1-15",
    meta: mfx_45auto_45renderer_45_451_4515Aw0a2uVCPdMeta || {},
    alias: mfx_45auto_45renderer_45_451_4515Aw0a2uVCPdMeta?.alias || [],
    redirect: mfx_45auto_45renderer_45_451_4515Aw0a2uVCPdMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/preview/@visiontree/mfx-auto-renderer--1-15.vue").then(m => m.default || m)
  },
  {
    name: mfx_45auto_45renderer_45_451_4516g8kqZovXSAMeta?.name ?? "preview-@visiontree-mfx-auto-renderer--1-16",
    path: mfx_45auto_45renderer_45_451_4516g8kqZovXSAMeta?.path ?? "/preview/@visiontree/mfx-auto-renderer--1-16",
    meta: mfx_45auto_45renderer_45_451_4516g8kqZovXSAMeta || {},
    alias: mfx_45auto_45renderer_45_451_4516g8kqZovXSAMeta?.alias || [],
    redirect: mfx_45auto_45renderer_45_451_4516g8kqZovXSAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/preview/@visiontree/mfx-auto-renderer--1-16.vue").then(m => m.default || m)
  },
  {
    name: mfx_45auto_45renderer_45_451_4517Wr3ljQAYF2Meta?.name ?? "preview-@visiontree-mfx-auto-renderer--1-17",
    path: mfx_45auto_45renderer_45_451_4517Wr3ljQAYF2Meta?.path ?? "/preview/@visiontree/mfx-auto-renderer--1-17",
    meta: mfx_45auto_45renderer_45_451_4517Wr3ljQAYF2Meta || {},
    alias: mfx_45auto_45renderer_45_451_4517Wr3ljQAYF2Meta?.alias || [],
    redirect: mfx_45auto_45renderer_45_451_4517Wr3ljQAYF2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/preview/@visiontree/mfx-auto-renderer--1-17.vue").then(m => m.default || m)
  },
  {
    name: mfx_45auto_45rendererobO9CLoNbgMeta?.name ?? "preview-@visiontree-mfx-auto-renderer",
    path: mfx_45auto_45rendererobO9CLoNbgMeta?.path ?? "/preview/@visiontree/mfx-auto-renderer",
    meta: mfx_45auto_45rendererobO9CLoNbgMeta || {},
    alias: mfx_45auto_45rendererobO9CLoNbgMeta?.alias || [],
    redirect: mfx_45auto_45rendererobO9CLoNbgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/preview/@visiontree/mfx-auto-renderer.vue").then(m => m.default || m)
  },
  {
    name: indexNqW3SY9OAqMeta?.name ?? "preview",
    path: indexNqW3SY9OAqMeta?.path ?? "/preview",
    meta: indexNqW3SY9OAqMeta || {},
    alias: indexNqW3SY9OAqMeta?.alias || [],
    redirect: indexNqW3SY9OAqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/preview/index.vue").then(m => m.default || m)
  },
  {
    path: _91id_93yH9ipzaVXBMeta?.path ?? "/resources/:id()",
    children: [
  {
    name: index9nNtXabxV8Meta?.name ?? "resources-id",
    path: index9nNtXabxV8Meta?.path ?? "",
    meta: index9nNtXabxV8Meta || {},
    alias: index9nNtXabxV8Meta?.alias || [],
    redirect: index9nNtXabxV8Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/resources/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: properties2C7wTsA1INMeta?.name ?? "resources-id-properties",
    path: properties2C7wTsA1INMeta?.path ?? "properties",
    meta: properties2C7wTsA1INMeta || {},
    alias: properties2C7wTsA1INMeta?.alias || [],
    redirect: properties2C7wTsA1INMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/resources/[id]/properties.vue").then(m => m.default || m)
  },
  {
    name: Questionnaire_45itemxKCDA7fzZ6Meta?.name ?? "resources-id-Questionnaire-item",
    path: Questionnaire_45itemxKCDA7fzZ6Meta?.path ?? "Questionnaire-item",
    meta: Questionnaire_45itemxKCDA7fzZ6Meta || {},
    alias: Questionnaire_45itemxKCDA7fzZ6Meta?.alias || [],
    redirect: Questionnaire_45itemxKCDA7fzZ6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/resources/[id]/Questionnaire-item.vue").then(m => m.default || m)
  },
  {
    name: settingsuASeJHhbNnMeta?.name ?? "resources-id-settings",
    path: settingsuASeJHhbNnMeta?.path ?? "settings",
    meta: settingsuASeJHhbNnMeta || {},
    alias: settingsuASeJHhbNnMeta?.alias || [],
    redirect: settingsuASeJHhbNnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/resources/[id]/settings.vue").then(m => m.default || m)
  }
],
    name: _91id_93yH9ipzaVXBMeta?.name ?? undefined,
    meta: _91id_93yH9ipzaVXBMeta || {},
    alias: _91id_93yH9ipzaVXBMeta?.alias || [],
    redirect: _91id_93yH9ipzaVXBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/resources/[id].vue").then(m => m.default || m)
  },
  {
    name: iframevf6WKBfmxlMeta?.name ?? "resources-import-iframe",
    path: iframevf6WKBfmxlMeta?.path ?? "/resources/import/iframe",
    meta: iframevf6WKBfmxlMeta || {},
    alias: iframevf6WKBfmxlMeta?.alias || [],
    redirect: iframevf6WKBfmxlMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/resources/import/iframe.vue").then(m => m.default || m)
  },
  {
    name: indexyutem8pLx0Meta?.name ?? "resources-import",
    path: indexyutem8pLx0Meta?.path ?? "/resources/import",
    meta: indexyutem8pLx0Meta || {},
    alias: indexyutem8pLx0Meta?.alias || [],
    redirect: indexyutem8pLx0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/resources/import/index.vue").then(m => m.default || m)
  },
  {
    name: indexTW6kGHxKT5Meta?.name ?? "resources",
    path: indexTW6kGHxKT5Meta?.path ?? "/resources",
    meta: indexTW6kGHxKT5Meta || {},
    alias: indexTW6kGHxKT5Meta?.alias || [],
    redirect: indexTW6kGHxKT5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/mfx-studio/pages/resources/index.vue").then(m => m.default || m)
  }
]
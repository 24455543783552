import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.3_@types+node@22.5.4_eslint@8.49.0_typescript@5.2.2_vue-tsc@1.8.11/node_modules/nuxt/dist/pages/runtime/validate.js";
export const globalMiddleware = [
  validate
]
export const namedMiddleware = {
  "debug-mode-only": () => import("/vercel/path0/apps/mfx-studio/middleware/debugModeOnly.ts"),
  "feature-flags": () => import("/vercel/path0/apps/mfx-studio/middleware/featureFlags.ts"),
  "import-resource": () => import("/vercel/path0/apps/mfx-studio/middleware/importResource.ts"),
  "questionnaire-item-context-exists": () => import("/vercel/path0/apps/mfx-studio/middleware/questionnaireItemContextExists.ts"),
  "resource-available": () => import("/vercel/path0/apps/mfx-studio/middleware/resourceAvailable.ts")
}
import { defineNuxtPlugin } from 'nuxt/app'
import PouchDB from 'pouchdb'

import { setup, performMigrations } from '@/client-api'

export default defineNuxtPlugin(async () => {
  const database = new PouchDB('mfx-studio')
  const api = setup(database)

  await performMigrations(api)

  return {
    provide: {
      api,
    },
  }
})

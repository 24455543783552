import get from 'lodash/get'

import {
  configSchema as latest,
  configSchema as v1_17,
  getTranslatedFields,
} from '@visiontree/mfx-auto-renderer'
import { configSchema as v1_11 } from '@visiontree/mfx-auto-renderer--1-11'
import { configSchema as v1_12 } from '@visiontree/mfx-auto-renderer--1-12'
import { configSchema as v1_13 } from '@visiontree/mfx-auto-renderer--1-13'
import { configSchema as v1_15 } from '@visiontree/mfx-auto-renderer--1-15'
import { configSchema as v1_16 } from '@visiontree/mfx-auto-renderer--1-16'

export const libraryConfigs = {
  '@visiontree/mfx-auto-renderer': latest,
  '@visiontree/mfx-auto-renderer--1-11': v1_11,
  '@visiontree/mfx-auto-renderer--1-12': v1_12,
  '@visiontree/mfx-auto-renderer--1-13': v1_13,
  '@visiontree/mfx-auto-renderer--1-15': v1_15,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
  '@visiontree/mfx-auto-renderer--1-16': v1_16 as any,
  '@visiontree/mfx-auto-renderer--1-17': v1_17,
}

const refToPath = (ref: string) => {
  return ref.replace(/^#\/?/i, '').replaceAll('/', '.')
}

interface StringSchema {
  type: 'string'
  enum?: string[]
  default?: string
}

interface BooleanSchema {
  type: 'boolean'
  default?: boolean
}

interface NumberSchema {
  type: 'number'
  default?: number
  minimum?: number
}

interface ObjectSchema {
  type: 'object'
  properties: Record<
    string,
    ObjectSchema | StringSchema | BooleanSchema | NumberSchema
  >
  additionalProperties?: boolean
}

const buildObject = (schema: ObjectSchema) => {
  const target: Record<
    keyof (typeof schema)['properties'],
    boolean | number | object | string
  > = {}

  Object.entries(schema.properties).forEach(([key, property]) => {
    if ('default' in property && property.default !== undefined) {
      target[key] = property.default
    }
    if (property.type === 'object') {
      target[key] = buildObject(property)
    }
  })

  return target
}

const isObjectSchema = (subject: unknown): subject is ObjectSchema => {
  return (
    typeof subject === 'object' &&
    !!subject &&
    'type' in subject &&
    subject.type === 'object' &&
    'properties' in subject
  )
}

export const buildInstanceFromSchema = (
  configName: keyof typeof libraryConfigs,
) => {
  // TODO: remove eslint-disable-next-line and fix config schema
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
  const schema = libraryConfigs[configName]

  // TODO: remove eslint-disable-next-line and fix config schema $ref
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
  const config = get(schema, refToPath(schema.$ref)) as unknown

  if (!isObjectSchema(config)) {
    throw new Error('invalid config schema')
  }

  return buildObject(config)
}

export const getFieldsFromSchema = (
  locale: string,
  configName: keyof typeof libraryConfigs,
) => {
  // TODO: remove eslint-disable-next-line and fix config schema
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
  const schema = libraryConfigs[configName]

  // TODO: remove eslint-disable-next-line and fix config schema
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return getTranslatedFields(locale, schema)
}

import { defineNuxtPlugin } from 'nuxt/app'
import { type Pinia } from 'pinia'
import { PiniaSharedState } from 'pinia-shared-state'

/**
 * Syncs store state between open tabs and windows. This makes sure that the MFX Studio works as
 * expected when it's opened in a new tab, and makes sure that things work as expected in case the
 * user is trying to edit the same source through multiple tabs.
 *
 * @see https://github.com/wobsoriano/pinia-shared-state
 */
export default defineNuxtPlugin((nuxtApp) => {
  const pinia = nuxtApp.$pinia as Pinia
  pinia.use(
    PiniaSharedState({
      enable: true,
    }),
  )
})

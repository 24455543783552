import { inject, type InjectionKey } from 'vue'

import clean from 'clean-deep'
import deepmerge from 'deepmerge'
import clone from 'lodash/cloneDeep'
import get from 'lodash/get'
import merge from 'lodash/merge'
import set from 'lodash/set'
import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'

import type r4 from 'fhir/r4'
import type { Questionnaire, QuestionnaireResponse } from 'fhir/r4'

import type { Resource, ResourceVersion } from '@/client-api/schema'
import { useAPI } from '@/composables/useAPI'
import { resolveLibraryVersion } from '@/utils/autoRenderer'
import { defaultVersion, type Version } from '@/utils/autoRendererVersions'
import { sortJSON, objectToJSON } from '@/utils/format'
import {
  type CheckResult,
  mfxAutoRendererQuestionnaireChecks,
  specificationChecks,
} from '@/utils/questionnaireChecks'
import { type MfxAutoRendererV1Config } from '@/utils/tempMfxAutoRendererV1Config.type'

export interface Source {
  id: string
  documentID: string
  versionDocumentID?: string
  displayName: string
  jsonString: string
  unedited: boolean
  isValidJSON: boolean
  jsonObject: object | undefined
  checks: {
    warningCount: number
    errorCount: number
    successCount: number
    specification: Record<string, CheckResult>
    autoRenderer: Record<string, CheckResult>
  }
  response: QuestionnaireResponse
  config: MfxAutoRendererV1Config
  version: Version
}

export type CodeSuggestion = Record<string, unknown>

export interface State {
  questionnaires: Source[]
  lockedBy: string | undefined
  /** Source used by the MFX Studio */
  active?: Source
  suggestions: CodeSuggestion[]
}

export type UpdatePropertyResponse =
  | {
      errors: r4.ElementDefinitionConstraint[]
      path: string
      current: object | string | number | boolean | undefined
      previous?: object | string | number | boolean | undefined
    }
  | {
      path: string
      current: object | string | number | boolean | undefined
      previous: object | string | number | boolean | undefined
      currentObject: r4.Questionnaire
    }

/**
 * Returns true if the string can be parsed into a valid JSON object.
 */
const checkValidJSON = (jsonString: string) => {
  try {
    const parsed = JSON.parse(jsonString) as unknown

    return typeof parsed === 'object' && !Array.isArray(parsed)
  } catch (error) {
    return false
  }
}

/**
 * Returns a simple questionnaire to give the user a hint as to what can be changed after creating
 * a new questionnaire.
 */
const buildInitialQuestionnaire = (title = 'Untitled'): Questionnaire => {
  return {
    resourceType: 'Questionnaire',
    status: 'draft',
    title,
    item: [
      {
        linkId: 'first-group',
        type: 'group',
        item: [],
      },
    ],
  }
}

const buildInitialResponse = (): QuestionnaireResponse => {
  return {
    resourceType: 'QuestionnaireResponse',
    status: 'in-progress',
  }
}

/**
 * Checks if the jsonString matches the initial questionnaire.
 */
const isInitialQuestionnaire = (jsonString: string) => {
  return JSON.stringify(buildInitialQuestionnaire(), null, 2) === jsonString
}

const removeNullProperties = (value: Record<string, unknown>) => {
  for (const key in value) {
    if (value[key] === null) {
      delete value[key]
    } else if (typeof value[key] === 'object') {
      removeNullProperties(value[key] as Record<string, unknown>)
    }
  }
}

const getCheckResults = (sourceString: string) => {
  let sourceObject: Partial<Questionnaire>

  try {
    sourceObject = JSON.parse(sourceString) as Partial<Questionnaire>
  } catch (error) {
    // 🤷‍♂️ not parsable, but that's sometimes expected.
  }
  const specification = Object.entries(specificationChecks).reduce(
    (checks, [checkName, checkFunction]) => {
      return { ...checks, [checkName]: checkFunction(sourceObject) }
    },
    {},
  )

  const autoRenderer = Object.entries(
    mfxAutoRendererQuestionnaireChecks,
  ).reduce((checks, [checkName, checkFunction]) => {
    return { ...checks, [checkName]: checkFunction(sourceObject) }
  }, {})

  const checks: CheckResult[] = [
    ...Object.values<CheckResult>(autoRenderer),
    ...Object.values<CheckResult>(specification),
  ]

  return {
    successCount: checks.filter((check) => check.success).length,
    errorCount: checks.filter(
      (check) => !check.success && check.severity === 'error',
    ).length,
    warningCount: checks.filter(
      (check) => !check.success && check.severity === 'warning',
    ).length,
    specification,
    autoRenderer,
  }
}

/**
 * Converts the questionnaire to source metadata.
 */
const buildQuestionnaireSource = (
  questionnaire = buildInitialQuestionnaire(),
  response = buildInitialResponse(),
): Source => {
  const questionnaireString = sortJSON(JSON.stringify(questionnaire, null, 2))

  const id = `q-${uuid()}`

  return {
    id: id,
    documentID: id, // TODO
    displayName: questionnaire.title || 'Untitled',
    jsonString: questionnaireString,
    unedited: isInitialQuestionnaire(questionnaireString),
    isValidJSON: checkValidJSON(questionnaireString),
    jsonObject: questionnaire,
    checks: getCheckResults(questionnaireString),
    response,
    config: {},
    version: defaultVersion,
  }
}

type ResourceDocument = PouchDB.Core.ExistingDocument<Resource>
type ResourceVersionDocument = PouchDB.Core.ExistingDocument<ResourceVersion>

const resourceDocumentToSource = (
  resourceDocument: ResourceDocument,
  resourceVersionDocument?: ResourceVersionDocument,
): Source => {
  const stringifiedResource = objectToJSON(
    resourceVersionDocument?.resource || {},
  )

  return {
    documentID: resourceDocument._id,
    versionDocumentID: resourceVersionDocument?._id,
    id: resourceDocument.legacyStorageKey || resourceDocument._id,
    displayName: resourceDocument.label || 'Untitled',
    jsonString: stringifiedResource,
    unedited: isInitialQuestionnaire(stringifiedResource),
    isValidJSON: checkValidJSON(stringifiedResource),
    jsonObject: resourceVersionDocument?.resource || {},
    checks: getCheckResults(stringifiedResource),
    response: resourceDocument.legacyResponse || buildInitialResponse(),
    config: resourceDocument.legacyConfig || {},
    version: resolveLibraryVersion(
      resourceDocument.legacyLibraryVersion || defaultVersion,
    ),
  }
}

function isDefined<T>(val: T | undefined | null): val is T {
  return val !== undefined && val !== null
}

/**
 * Global state for questionnaire sources.
 */
export const getSourcesStore = async () => {
  const api = useAPI()

  const resources = await api.Resources.all()

  const sources = await Promise.all(
    resources.rows
      .map((row) => row.doc)
      .filter(isDefined)
      .map(async (resourceDocument) => {
        const resourceVersion = resourceDocument.currentVersionID
          ? await api.ResourceVersions.get(resourceDocument.currentVersionID)
          : undefined

        return resourceDocumentToSource(resourceDocument, resourceVersion)
      }),
  )
  return defineStore('sources', {
    state: (): State => {
      return {
        questionnaires: sources,
        lockedBy: undefined,
        suggestions: [],
        active: undefined,
      }
    },
    getters: {
      emptyStateQuestionnaire(state) {
        return state.questionnaires.find((questionnaire) => {
          return questionnaire.unedited
        })
      },
      /** Returns the source with the matching id if present. */
      getById(state) {
        return (id: string) => {
          return state.questionnaires.find((questionnaire) => {
            return questionnaire.id === id
          })
        }
      },
      getSuggestedJsonString(state) {
        return (source?: Source, suggestions?: CodeSuggestion[]) => {
          if (!source) {
            return ''
          }

          if (!source.isValidJSON) {
            return source.jsonString
          }

          if (!state.suggestions.length) {
            return source.jsonString
          }

          let suggestionsToMerge = this.suggestions
          if (suggestions) {
            suggestionsToMerge = suggestions
          }

          const mergedResult = deepmerge.all(
            [JSON.parse(source.jsonString) as object, ...suggestionsToMerge],
            {
              arrayMerge(target, source, options) {
                const output = target.slice()

                source.forEach((item, index) => {
                  if (typeof output[index] === 'undefined') {
                    output[index] = options?.cloneUnlessOtherwiseSpecified(
                      item as object,
                      options,
                    )
                  } else if (options?.isMergeableObject(item as object)) {
                    output[index] = deepmerge<object>(
                      target[index] as object,
                      item as object,
                      options,
                    )
                  } else if (target.indexOf(item) === -1) {
                    output.push(item as object)
                  }
                })

                return output as unknown[]
              },
            },
          )

          removeNullProperties(mergedResult as Record<string, unknown>)

          return objectToJSON(mergedResult)
        }
      },
    },
    actions: {
      /**
       * Creates a new source and selects it for the mfx-studio.
       */
      async create(
        questionnaire?: Questionnaire,
        response?: QuestionnaireResponse,
        config?: MfxAutoRendererV1Config,
        version?: Version,
      ) {
        // The emptyStateQuestionnaire acts as the initial placeholder for new questionnaires, if it's
        // untouched then we'll just try to edit that one instead of making a new empty questionnaire.
        const initialEmptyQuestionnaire = this.emptyStateQuestionnaire
        if (initialEmptyQuestionnaire) {
          if (questionnaire) {
            await this.update(initialEmptyQuestionnaire, {
              jsonString: sortJSON(JSON.stringify(questionnaire)),
            })
          }

          if (config) {
            await this.updateConfig(initialEmptyQuestionnaire, config)
          }

          if (version) {
            await this.updateVersion(initialEmptyQuestionnaire, version)
          }

          if (response) {
            await this.updateResponse(initialEmptyQuestionnaire, response)
          }

          this.select(initialEmptyQuestionnaire)

          return initialEmptyQuestionnaire
        }

        const source = buildQuestionnaireSource(questionnaire, response)

        if (config) {
          source.config = config
        }

        if (version) {
          source.version = version
        }

        const resourceDocument = await api.Resources.create({
          type: 'Questionnaire',
          label: source.displayName,
          legacyLibraryVersion: resolveLibraryVersion(source.version),
          legacyConfig: source.config,
          legacyResponse: source.response,
          legacyStorageKey: source.id,
        })

        const resourceVersionDocument = await api.ResourceVersions.create({
          resourceID: resourceDocument._id,
          resource: source.jsonObject || {},
          version:
            typeof source.jsonObject === 'object' &&
            'version' in source.jsonObject
              ? String(source.jsonObject.version)
              : '0.0.0',
          url:
            typeof source.jsonObject === 'object' && 'url' in source.jsonObject
              ? String(source.jsonObject.url)
              : `https://studio.visiontree.com/resources/${resourceDocument.slug}`,
        })

        await api.Resources.update(resourceDocument, {
          currentVersionID: resourceVersionDocument._id,
        })

        source.documentID = resourceDocument._id
        source.versionDocumentID = resourceVersionDocument._id

        this.questionnaires.push(source)
        this.select(source)

        return source
      },

      /**
       * Removes the source from the store. Makes sure the store isn't empty and updates the mfx-studio
       * source if the removed source was the active source.
       */
      async remove(source?: Source) {
        if (!source) {
          return
        }

        // If the source is unedited and is the only questionnaire remaining then we'll leave it to
        // act as the empty state.
        if (source.unedited && this.questionnaires.length === 1) {
          return
        }

        await api.Resources.remove(source.documentID)

        this.questionnaires = this.questionnaires.filter((current) => {
          return source.id !== current.id
        })

        if (!this.questionnaires.length) {
          await this.create()
        } else if (source.id === this.active?.id) {
          this.select(this.questionnaires.at(0))
        }
      },

      getPropertyAtPath(source: Source | undefined, path: string) {
        if (!source || !source.jsonObject) {
          return
        }

        return get({ Questionnaire: source.jsonObject }, path) as
          | object
          | string
          | number
          | boolean
          | undefined
      },

      async updateProperty(
        source: Source | undefined,
        path: string,
        value: object | string | number | boolean | undefined,
      ): Promise<UpdatePropertyResponse> {
        if (!source || !source.jsonObject || this.lockedBy) {
          return { errors: [], path, current: value }
        }

        this.lockedBy = path

        const target = clone({ Questionnaire: source.jsonObject })

        const previous = get(target, path) as
          | object
          | string
          | number
          | boolean
          | undefined

        set(target, path, value)

        const updatedObject = clean(target.Questionnaire, {
          emptyArrays: false /* empty item array needed for drag/drop */,
          emptyStrings:
            false /* empty strings needed for repeating property forms */,
        }) as r4.Questionnaire

        await this.update(source, {
          jsonString: sortJSON(JSON.stringify(updatedObject)),
        })

        this.lockedBy = undefined

        return {
          path,
          previous,
          current: value,
          currentObject: updatedObject,
        }
      },

      /**
       * Updates the source with the provided changes.
       */
      async update(
        source: Source | undefined,
        changes: Pick<Source, 'jsonString'>,
      ) {
        if (!source) {
          return
        }

        const index = this.questionnaires.findIndex((current) => {
          return current.id === source.id
        })

        let parsed = undefined

        try {
          parsed = JSON.parse(changes.jsonString) as object
        } catch (error) {
          return // only persist valid JSON
        }

        const resourceVersionDocument = await api.ResourceVersions.get(
          source.versionDocumentID || '',
        )

        if (!resourceVersionDocument) {
          return // TODO
        }

        const updatedVersion = await api.ResourceVersions.update(
          resourceVersionDocument,
          {
            resource: parsed,
          },
        )

        const displayName =
          'title' in updatedVersion.resource
            ? String(updatedVersion.resource.title)
            : source.displayName

        if (displayName !== source.displayName) {
          await api.Resources.update(
            await api.Resources.get(source.documentID),
            {
              label: displayName,
            },
          )
        }

        const updatedVersionString = objectToJSON(updatedVersion.resource)

        const updatedSource = {
          ...this.questionnaires[index],
          jsonString: updatedVersionString,
          jsonObject: updatedVersion.resource,
          isValidJSON: true,
          unedited: isInitialQuestionnaire(updatedVersionString),
          displayName,
          checks: getCheckResults(updatedVersionString),
        }

        this.questionnaires[index] = updatedSource

        return this.questionnaires[index]
      },

      async updateConfig(
        source: Source | undefined,
        changes: Partial<MfxAutoRendererV1Config>,
      ) {
        if (!source) return

        const config = merge(source.config ?? {}, changes)

        await this.setConfig(source, config)
      },

      async setConfig(
        source: Source | undefined,
        config: MfxAutoRendererV1Config,
      ) {
        if (!source) {
          return
        }

        const resourceDocument = await api.Resources.get(source.documentID)

        if (!resourceDocument) {
          return
        }

        await api.Resources.update(resourceDocument, {
          legacyConfig: config,
        })

        const questionnaire = this.questionnaires.find(
          (questionnaire) => questionnaire.id === source.id,
        )
        if (questionnaire) {
          questionnaire.config = config
        }
      },

      async updateResponse(
        source: Source | undefined,
        response: QuestionnaireResponse,
      ) {
        if (!source) {
          return
        }

        const resourceDocument = await api.Resources.get(source.documentID)

        if (!resourceDocument) {
          return
        }

        await api.Resources.update(resourceDocument, {
          legacyResponse: clone(response),
        })

        const questionnaire = this.questionnaires.find(
          (questionnaire) => questionnaire.id === source.id,
        )

        if (questionnaire) {
          questionnaire.response = clone(response)
        }
      },

      async updateVersion(source: Source | undefined, version: Version) {
        if (!source) {
          return
        }

        const resourceDocument = await api.Resources.get(source.documentID)

        if (!resourceDocument) {
          return
        }

        await api.Resources.update(resourceDocument, {
          legacyLibraryVersion: resolveLibraryVersion(version),
        })

        const questionnaire = this.questionnaires.find(
          (questionnaire) => questionnaire.id === source.id,
        )
        if (questionnaire) {
          questionnaire.version = version
        }
      },

      /**
       * Sets the mfx-studio source.
       */
      select(source?: Source) {
        this.active = source
      },

      addSuggestion(suggestion: CodeSuggestion) {
        if (Object.keys(suggestion).length > 0) {
          this.suggestions.push(suggestion)
        }
      },

      clearSuggestions() {
        this.suggestions = []
      },

      async applySuggestion(source?: Source, json?: CodeSuggestion) {
        if (!json || !source) {
          return
        }

        await this.update(source, {
          jsonString: this.getSuggestedJsonString(source, [json]),
        })

        this.removeSuggestion(json)
      },

      removeSuggestion(json?: CodeSuggestion) {
        if (!json) {
          return
        }
        const insideContent = JSON.stringify(json)

        this.suggestions = this.suggestions.filter((suggestion) => {
          return JSON.stringify(suggestion) !== insideContent
        })
      },
    },
  })()
}

export const sourcesStoreKey = Symbol() as InjectionKey<
  Awaited<ReturnType<typeof getSourcesStore>>
>

export const useSourcesStore = () => {
  return inject(sourcesStoreKey) as Awaited<ReturnType<typeof getSourcesStore>>
}

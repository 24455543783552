import type { Migration } from './schema'
import type { MFXStudioAPI } from './setup'

import { reportError } from '@/utils/reportError'

export interface MigrationHandlerOptions {
  // Unique name - preferably in the format `YYYY-MM-DD-short-description` which should match the handler file name
  name: string
  // Only `ready` migrations will be handled so that migrations can be merged and developed over time without being run prematurely.
  status: 'active' | 'in-development'
  // Performs the migration
  up: (api: MFXStudioAPI) => Promise<void>
}

export interface MigrationHandler {
  (api: MFXStudioAPI): Promise<void>
}

/**
 * Defines a migration handler which is used by the `performMigrations`.
 */
export const defineMigrationHandler = (
  options: MigrationHandlerOptions,
): MigrationHandler => {
  return async (api: MFXStudioAPI) => {
    // Ignore any migrationHandler that isn't considered ready yet.
    if (options.status !== 'active') {
      return
    }

    const migrationID = `Migrations_${options.name}`

    try {
      // Check to see if the migration has been run already.
      await api.get<Migration>(migrationID)
    } catch (expectedError) {
      // Run the migration and then record it having been run so that we don't run it again in the future.
      try {
        await options.up(api)
        await api.$$database.put<Migration>({
          _id: migrationID,
          slug: options.name,
          createdAt: new Date().toISOString(),
        })
      } catch (migrationError) {
        reportError(`Failed to migrate: ${options.name}`, migrationError)
      }
    }
  }
}

import set from 'lodash/set'
import { unparse, parse, ParseError } from 'papaparse'
import sanitizeHtml from 'sanitize-html'
import showdown from 'showdown'

import type r4 from 'fhir/r4'

const getTypeOrder = (type: string) => {
  switch (type) {
    case 'number':
    case 'string':
    case 'boolean':
      return 1
    case 'object':
      return 2
    case 'array':
      return 3
    default:
      return 4
  }
}

/**
 * Joins the key with any prefix with a `.` delimiter.
 */
const formatKey = (key: string, prefix = ''): string => {
  return [prefix, key].filter(Boolean).join('.')
}

/**
 * Recursively builds a flat array of all leaf-node keys within the input object. Array properties
 * are indicated with a `[]` suffix, and nested properties are delimited with a `.`.
 */
const flattenKeys = (input: object, parentKey = '') => {
  let keys: string[] = []

  Object.entries(input).forEach(([key, value]) => {
    const currentKey = formatKey(key, parentKey)

    if (
      Array.isArray(value) &&
      value.every((item) => typeof item === 'object')
    ) {
      Object.values(value).forEach((item) => {
        keys = keys.concat(flattenKeys(item as object, currentKey + '[]'))
      })
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      keys = keys.concat(flattenKeys(value as object, currentKey))
    } else {
      keys.push(currentKey)
    }
  })

  // Remove duplicates
  return [...new Set(keys)]
}

const sortObjectProperties = (jsonObject: Record<string, unknown>) => {
  const sortedObj: Record<string, unknown> = {}

  const keys = Object.keys(jsonObject).sort((a, b) => {
    const aValue = jsonObject[a]
    const bValue = jsonObject[b]
    const aType = Array.isArray(aValue) ? 'array' : typeof aValue
    const bType = Array.isArray(bValue) ? 'array' : typeof bValue

    const aBase = a.startsWith('_') ? a.slice(1) : a
    const bBase = b.startsWith('_') ? b.slice(1) : b

    if (aType !== bType) {
      // sorts in type order (primitive, object, array)
      return getTypeOrder(aType) - getTypeOrder(bType)
    } else if (aBase !== bBase) {
      // sorts in alphabetical order
      return aBase.localeCompare(bBase)
    } else {
      // sorts meta properties next to corresponding base property
      return a.startsWith('_') ? 1 : -1
    }
  })

  // sorts nested properties
  for (let i = 0; i < keys.length; i++) {
    const key: keyof typeof jsonObject = keys[i]

    if (typeof jsonObject[key] === 'object' && jsonObject[key] !== null) {
      // If its an array sort objects in it
      if (Array.isArray(jsonObject[key])) {
        sortedObj[key] = (jsonObject[key] as Array<unknown>).map((item) => {
          if (typeof item === 'object' && item !== null) {
            return sortObjectProperties(item as Record<string, unknown>)
          } else {
            return item
          }
        })
      } else {
        sortedObj[key] = sortObjectProperties(
          jsonObject[key] as Record<string, unknown>,
        )
      }
    } else {
      sortedObj[key] = jsonObject[key]
    }
  }

  return sortedObj
}

const getType = (thing: unknown): string => {
  if (Array.isArray(thing)) {
    return 'array'
  }
  return typeof thing
}

export interface Input {
  [key: string]: unknown
}

/**
 * Internal function used to recursively convert a deeply nested object to our custom CSV format.
 */
const convertToCsv = (
  input: Input,
  keys: string[],
  output: (string | number)[][] = [],
  prefix = '',
  parentRow = 0,
) => {
  Object.entries(input).forEach(([key, value]) => {
    const type = getType(value)
    const currentKey = formatKey(key, prefix)

    if (!Array.isArray(output[parentRow])) {
      output[parentRow] = []
    }

    if (type === 'array') {
      ;(value as Input[]).forEach((item: Input) => {
        convertToCsv(item, keys, output, currentKey + '[]', output.length)
      })
    } else if (type === 'object') {
      convertToCsv(value as Input, keys, output, currentKey, parentRow)
    } else {
      const index = keys.indexOf(currentKey)
      output[parentRow][index] = value as string
    }
  })

  return output
}

/**
 * converts a deeply nested object into a CSV string.
 */
export const objectToCSV = (input: Input): string => {
  const sortedObject = sortObjectProperties(input)
  const keys = flattenKeys(sortedObject)

  const output = convertToCsv(sortedObject, keys)

  return unparse([keys, ...output], { skipEmptyLines: true })
}

export const objectToJSON = (input: object): string => {
  return JSON.stringify(input, null, 2)
}

export const formatJSON = (value: string) => {
  try {
    return objectToJSON(JSON.parse(value) as Input)
  } catch (error) {
    return value
  }
}

export const sortJSON = (value: string) => {
  try {
    return objectToJSON(
      sortObjectProperties(JSON.parse(value) as Record<string, unknown>),
    )
  } catch (error) {
    return value
  }
}

/**
 * Returns true if the string can be parsed into a valid JSON object.
 */
export const checkValidJSON = (jsonString: string) => {
  try {
    JSON.parse(jsonString)

    return true
  } catch (error) {
    return false
  }
}

interface ParsedError {
  errors: ParseError[]
  data?: never
}

type Parsed = {
  data: Record<string, unknown>
  errors?: never
}

export const parseCSV = (input: string): ParsedError | Parsed => {
  const parsed = parse<string[]>(input)

  const output: Record<string, unknown> = {}
  const indicesByKey: Record<string, number> = {}

  if (parsed.errors.length) {
    return { errors: parsed.errors }
  }

  const [keys, ...rows] = parsed.data

  for (const row of rows) {
    let incrementLastArray = true
    let indicesUpdated = false

    for (const [valueIndex, value] of row.entries()) {
      if (!value) {
        continue
      }

      const key = keys[valueIndex]

      // Since the column keys don't contain indices, we have to reverse engineer them. Each row
      // represents a new object, so at the start of each row we'll go through and update the indices
      // for that row. We know that the last array in the tree will be the only one that increments.
      // and any descendent arrays will need to reset. Array indices not in use start at -1 so that
      // once we increment them they start at 0.
      if (!indicesUpdated) {
        const splitKey = key.split('[]').slice(0, -1)

        splitKey.forEach((keyPart, keyPartIndex) => {
          const keyToIndex = [
            splitKey.slice(0, keyPartIndex).join('[]'),
            `${keyPart}[]`,
          ]
            .filter(Boolean)
            .join('[]')

          if (!(keyToIndex in indicesByKey)) {
            indicesByKey[keyToIndex] = -1
          }

          const isLastArray = keyPartIndex === splitKey.length - 1

          if (isLastArray) {
            const childrenKeys = Object.keys(indicesByKey).filter(
              (potentialChildKey) => {
                return potentialChildKey.startsWith(`${keyToIndex}.`)
              },
            )

            // Descendants restart since the next time they show up they'll be in a new array
            for (const childKey of childrenKeys) {
              indicesByKey[childKey] = -1
            }

            // Only the last array in the tree gets incremented as we're still in the same nested object/array
            if (incrementLastArray) {
              indicesByKey[keyToIndex] += 1
              incrementLastArray = false
            }
          }
        })

        // Make sure we don't run this for every since property defined in the object, just once will do
        indicesUpdated = true
      }

      // Inject the indices calculated above into the key so that we know how to properly store the value.
      const keyWithIndices = key
        .split('[]')
        .reduce((combined, part, partIndex, partsArray) => {
          const nextPart = combined + part

          if (partIndex === partsArray.length - 1) {
            return nextPart
          }

          const nextIndex = indicesByKey[nextPart.replaceAll(/\d/g, '') + '[]']

          return `${combined}${part}[${nextIndex}]`
        }, '')

      set(output, keyWithIndices, value)
    }
  }

  return { data: output }
}

const flattenQuestionnaireItems = (
  items?: r4.QuestionnaireItem[],
): r4.QuestionnaireItem[] => {
  if (!Array.isArray(items)) {
    return []
  }

  return items
    .map((item) => {
      if (item.type === 'group' && Array.isArray(item.item)) {
        return flattenQuestionnaireItems(item.item)
      }

      return item
    })
    .flat()
}

export const questionnaireToDataDictionary = (
  input: r4.Questionnaire,
): string => {
  const keys = [
    'FormName',
    'FormID',
    'Timepoint',
    'QuestionText',
    'CustomQuestionText',
    'OID',
    'InputType',
    'AnswerValue',
    'CustomAnswerValue',
    'AnswerText',
  ] as const

  const items = flattenQuestionnaireItems(input.item)

  const itemRows = items
    .map((item) => {
      const fieldDetails = {
        // Since this is intended to be a human-readable output we'll prefer the title over the name.
        FormName: input.title || input.name,
        // Prefer the url over the id since that's what's used as the external identifier for the form.
        FormID: input.url || input.id,
        QuestionText: item.text,
        // No way to know which code is the "OID" so we'll just include them all.
        OID: item?.code
          ?.map((code) => {
            return code.code
          })
          .filter(Boolean)
          .join(', '),
        InputType: item.type,
        // Default to undefined, but if there are answerOptions defined we'll add them later.
        AnswerValue: undefined,
        AnswerText: undefined,
        /**
         * "Timepoint" represents basically a time-based label for when a Questionnaire appears in a
         * series of scenarios in a patient's journey (eg: 1-mo, 2-mo,6-mo, etc.). Questionnaires
         * don't explicitly have a concept for this so we'll leave it blank.
         */
        Timepoint: undefined,
        // We don't have any way to represent custom question text directly in the Questionnaire so we can ignore this
        CustomQuestionText: undefined,
        // We don't have any way to represent custom question answers directly in the Questionnaire so we can ignore this
        CustomAnswerValue: undefined,
      }

      if (Array.isArray(item.answerOption)) {
        return item.answerOption.map((answerOption, answerOptionIndex) => {
          // The "Data Dictionary" spec only includes form field details for the first answer option,
          // after that only the value/display text should be included.
          const includeFieldDetails = answerOptionIndex === 0

          let answerValue = ''
          let answerText = ''

          const [key, value] =
            Object.entries(answerOption)
              .filter(([key]) => {
                return key.startsWith('value')
              })
              .at(0) || []

          if (key === 'valueCoding') {
            const codingValue = value as r4.Coding // Based on the key we can infer the value type.

            answerText = codingValue.display || ''
            answerValue = codingValue.code || ''
          } else if (key === 'valueReference') {
            const referenceValue = value as r4.Reference // Based on the key we can infer the value type.

            answerText = referenceValue.display || ''
            answerValue = referenceValue.reference || ''
          } else {
            answerText = String(value)
            answerValue = String(value)
          }

          return {
            ...(includeFieldDetails && fieldDetails),
            AnswerValue: answerValue,
            AnswerText: answerText,
          }
        })
      }

      return fieldDetails
    })
    .flat()
    .map((data) => {
      return keys.map((key) => {
        return data[key] || ''
      })
    })

  return unparse([keys, ...itemRows])
}

const converter = new showdown.Converter({
  noHeaderId: true,
  omitExtraWLInCodeBlocks: true,
})

converter.setFlavor('github')

export const markdownToSanitizedHTML = (markdown: string) => {
  return sanitizeHtml(converter.makeHtml(markdown))
}

export const htmlToMarkdown = (html: string) => {
  return converter.makeMarkdown(sanitizeHtml(html))
}

import { defineNuxtPlugin } from 'nuxt/app'

/**
 * Ensures that the Naive UI CSS is loaded after Tailwind CSS so that it can override Tailwind CSS.
 *
 * @see https://www.naiveui.com/en-US/os-theme/docs/style-conflict
 */
export default defineNuxtPlugin(() => {
  const meta = document.createElement('meta')

  meta.name = 'naive-ui-style'
  document.head.appendChild(meta)
})

import { defineAppConfig } from '#imports'

const previewEmbedUrlRegex = /.*\/preview\/.*disableReload=true.*/i

export default defineAppConfig({
  vercelAnalytics: {
    mode: 'auto',
    beforeSend: (event) => {
      // Embedded previews should not be tracked since we're already tracking the page its embedded in.
      if (event.url.match(previewEmbedUrlRegex)) {
        return null
      }

      return event
    },
  },
})

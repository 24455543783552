import type r4 from 'fhir/r4'

import { Version } from '@/utils/autoRendererVersions'
import type { MfxAutoRendererV1Config } from '@/utils/tempMfxAutoRendererV1Config.type'

export interface SaveData {
  key: string
  config: MfxAutoRendererV1Config
  sources: Partial<r4.Questionnaire>[]
  responses: Partial<r4.QuestionnaireResponse>[]
  version: Version
}

type SaveRequest = Omit<SaveData, 'key'>

const get = async (key: string) => {
  try {
    const response = await fetch(
      `/api/s3Handler?key=${encodeURIComponent(key)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const data = (await response.json()) as SaveData
    return data
  } catch (error) {
    throw Error(`Could not get data: ${String(error)}`)
  }
}

const save = async (data: SaveRequest) => {
  try {
    const response = await fetch('/api/s3Handler', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: data }),
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const result = (await response.json()) as SaveData
    return result
  } catch (error) {
    throw Error(`Could not save data: ${String(error)}`)
  }
}

export const useExternalStorage = () => {
  return { get, save }
}

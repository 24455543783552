import type {
  Database,
  RecordID,
  ResourceVersion,
  Resource,
  Createable,
  Application,
  ResourceDocument,
} from './schema'

import { options } from '@/generated/mfxAutoRenderer'
import { buildInstanceFromSchema, libraryConfigs } from '@/utils/libraryConfig'

import { generateRecordMeta } from './utils'

export const setup = (database: Database) => {
  return {
    $$database: database,
    get<RecordType = unknown>(id: RecordID) {
      return database.get<RecordType>(id)
    },
    Applications: {
      async get(slug: string) {
        return await database.get<Application>(`Applications_${slug}`)
      },
      async getWithRelated(slug: string) {
        const document = await database.get<Application>(`Applications_${slug}`)

        return {
          ...document,
          resourceDocuments: (
            await database.allDocs<Resource>({
              include_docs: true,
              keys: document.resources,
            })
          ).rows
            .map((value) => {
              return 'doc' in value ? value?.doc : undefined
            })
            .filter((value): value is ResourceDocument => {
              return !!value
            }),
        }
      },
      all() {
        return database.allDocs<Application>({
          include_docs: true,
          startkey: 'Applications_',
          endkey: 'Applications_\ufff0',
        })
      },
      async create(application: Createable<Application>) {
        const libraryVersion = options.at(-1)?.value
        const response = await database.put({
          ...application,
          dependencies: {
            '@visiontree/mfx-auto-renderer': {
              version: libraryVersion,
              config:
                libraryVersion && libraryVersion in libraryConfigs
                  ? buildInstanceFromSchema(libraryVersion)
                  : {},
            },
            ...application.dependencies,
          },
          ...generateRecordMeta('Applications'),
        })

        return database.get<Application>(response.id)
      },
      async update(
        application: PouchDB.Core.ExistingDocument<Application> | string,
        changes: Partial<Application>,
      ) {
        const document =
          typeof application === 'string'
            ? await database.get(application)
            : application

        const response = await database.put({
          ...document,
          ...changes,
        })

        return database.get<Application>(response.id)
      },
      async remove(
        application: PouchDB.Core.ExistingDocument<Application> | string,
      ) {
        if (typeof application === 'string') {
          const document = await database.get(application)

          return database.remove(document)
        }

        return database.remove(application)
      },
    },
    Resources: {
      on(eventName: 'change', callback: () => unknown) {
        return database.changes({ since: 'now' }).on(eventName, callback)
      },
      get(slugOrID: string) {
        return database.get<Resource>(
          slugOrID.startsWith('Resources_')
            ? slugOrID
            : `Resources_${slugOrID}`,
        )
      },
      all() {
        return database.allDocs<Resource>({
          include_docs: true,
          startkey: 'Resources_',
          endkey: 'Resources_\ufff0',
        })
      },
      async update(
        resource: PouchDB.Core.ExistingDocument<Resource>,
        changes: Partial<Resource>,
      ) {
        const response = await database.put({
          ...resource,
          ...changes,
        })

        return database.get<Resource>(response.id)
      },

      async create(resource: Createable<Resource>) {
        const response = await database.put({
          ...resource,
          ...generateRecordMeta('Resources'),
        })

        return database.get<Resource>(response.id)
      },
      async remove(resource: PouchDB.Core.ExistingDocument<Resource> | string) {
        if (typeof resource === 'string') {
          const resourceDocument = await database.get(resource)

          return database.remove(resourceDocument)
        }

        return database.remove(resource)
      },
    },
    ResourceVersions: {
      all(options: { resourceID: RecordID } | { keys: RecordID[] }) {
        if ('keys' in options) {
          return database.allDocs({
            include_docs: true,
            keys: options.keys,
          })
        }

        return database.allDocs({
          include_docs: true,
          startkey: `ResourceVersions_${options.resourceID}_`,
          endkey: `ResourceVersions_${options.resourceID}_\ufff0`,
        })
      },
      get(id: string) {
        return database.get<ResourceVersion>(id)
      },
      async create(resourceVersion: Createable<ResourceVersion>) {
        const response = await database.put({
          ...resourceVersion,
          ...generateRecordMeta('ResourceVersions', resourceVersion.resourceID),
        })

        return database.get<ResourceVersion>(response.id)
      },
      async update(
        resourceVersion: PouchDB.Core.ExistingDocument<ResourceVersion>,
        changes: Partial<ResourceVersion>,
      ) {
        const response = await database.put({
          ...resourceVersion,
          ...changes,
        })

        return database.get<ResourceVersion>(response.id)
      },
    },
  }
}

export type MFXStudioAPI = ReturnType<typeof setup>

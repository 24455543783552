import revive_payload_client_F3ytsKJ35r from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.3_@types+node@22.5.4_eslint@8.49.0_typescript@5.2.2_vue-tsc@1.8.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_x2egYbeuym from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.3_@types+node@22.5.4_eslint@8.49.0_typescript@5.2.2_vue-tsc@1.8.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6nD7v8vqxZ from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.3_@types+node@22.5.4_eslint@8.49.0_typescript@5.2.2_vue-tsc@1.8.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_uYTU6Kj24W from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.2.2_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/mfx-studio/.nuxt/components.plugin.mjs";
import prefetch_client_hgqRDHWK9b from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.3_@types+node@22.5.4_eslint@8.49.0_typescript@5.2.2_vue-tsc@1.8.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_sKkrMiLhC7 from "/vercel/path0/node_modules/.pnpm/nuxt-vercel-analytics@1.0.0/node_modules/nuxt-vercel-analytics/dist/runtime/plugin.client.mjs";
import composition_0Nn0lUyaBn from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.11_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_3F07EH54hE from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.11_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_ZxzKl8EEJl from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.3_@types+node@22.5.4_eslint@8.49.0_typescript@5.2.2_vue-tsc@1.8.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import api_client_hHF76B0Hrp from "/vercel/path0/apps/mfx-studio/plugins/api.client.ts";
import datadog_client_cfAAU11B0P from "/vercel/path0/apps/mfx-studio/plugins/datadog.client.ts";
import naive_ui_client_v1rSuw08LE from "/vercel/path0/apps/mfx-studio/plugins/naive-ui.client.ts";
import pinia_persisted_state_client_EVg1kwsoXl from "/vercel/path0/apps/mfx-studio/plugins/pinia-persisted-state.client.ts";
import pinia_shared_state_client_KrOXCF7p7p from "/vercel/path0/apps/mfx-studio/plugins/pinia-shared-state.client.ts";
export default [
  revive_payload_client_F3ytsKJ35r,
  unhead_x2egYbeuym,
  router_6nD7v8vqxZ,
  plugin_vue3_uYTU6Kj24W,
  components_plugin_KR1HBZs4kY,
  prefetch_client_hgqRDHWK9b,
  plugin_client_sKkrMiLhC7,
  composition_0Nn0lUyaBn,
  i18n_3F07EH54hE,
  chunk_reload_client_ZxzKl8EEJl,
  api_client_hHF76B0Hrp,
  datadog_client_cfAAU11B0P,
  naive_ui_client_v1rSuw08LE,
  pinia_persisted_state_client_EVg1kwsoXl,
  pinia_shared_state_client_KrOXCF7p7p
]
import { objectToJSON } from '#imports'

import type { State as SourcesStoreState } from '@/stores/sources'

import { defineMigrationHandler } from '../defineMigrationHandler'

const isLocalStorageData = (input: unknown): input is SourcesStoreState => {
  const subject = input as Partial<SourcesStoreState>

  if (!subject || !Array.isArray(subject.questionnaires)) {
    return false
  }

  return subject.questionnaires.every((maybeSource) => {
    return maybeSource.id && maybeSource.jsonString
  })
}

const getLocalStorageData = () => {
  try {
    const data = JSON.parse(
      window.localStorage.getItem('sources') || '',
    ) as unknown

    if (!isLocalStorageData(data)) {
      throw new Error()
    }

    return data
  } catch (error) {
    return undefined
  }
}

const isObject = (subject: unknown): subject is object => {
  return typeof subject === 'object'
}

/**
 * Copies the user's legacy localStorage data to the PouchDB table.
 */
export default defineMigrationHandler({
  name: '2024-02-21-copy-local-storage',
  status: 'active',
  async up(api) {
    const legacyData = getLocalStorageData()

    if (!legacyData) {
      return
    }

    for (const source of legacyData.questionnaires) {
      // eslint-disable-next-line no-await-in-loop
      const resource = await api.Resources.create({
        label: source.displayName,
        type: 'Questionnaire',
        legacyLibraryVersion: source.version,
        legacyConfig: source.config,
        legacyResponse: source.response,
        legacyStorageKey: source.id,
      })

      const fallbackURL = `https://studio.visiontree.com/resources/${resource.slug}`

      let resourceObject: object = {}

      try {
        const parsed = JSON.parse(source.jsonString) as unknown

        if (!isObject(parsed)) {
          throw new Error('invalid format')
        }

        resourceObject = parsed
      } catch (error) {
        resourceObject = { invalidJSON: source.jsonString }
      }

      // eslint-disable-next-line no-await-in-loop
      const resourceVersion = await api.ResourceVersions.create({
        resourceID: resource._id,
        url: 'url' in resourceObject ? String(resourceObject.url) : fallbackURL,
        version:
          'version' in resourceObject
            ? String(resourceObject.version)
            : '0.0.0',
        resource: resourceObject,
      })

      // eslint-disable-next-line no-await-in-loop
      await api.Resources.update(resource, {
        currentVersionID: resourceVersion._id,
      })
    }
  },
})
